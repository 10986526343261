import Api from '@/services/Api'
import { BASE_URL } from '../config/config'

export default {
  findAll (param) {
    return Api().post('eventoAnexos', param)
  },
  insert (item) {
    return Api().post('eventoAnexo', item)
  },
  update (item) {
    return Api().put('eventoAnexo', item)
  },
  delete (item) {
    return Api().delete(`eventoAnexo?id=${item.id}`, item)
  },
  findById (item) {
    const url = `eventoAnexo/${item.id}`
    return Api().get(url)
  },
  download (idEventoAnexo) {
    // const requestOptions ={ responseType: 'arraybuffer' };
    const url = `eventoAnexo/download/${idEventoAnexo}`

    let link = document.createElement('a')
    link.href = BASE_URL + '/' + url
    // link.download = file
    link.target = 'blank'
    link.click();
    // return Api().get(url, requestOptions).then((response) => {
    //   console.log('response -> ', response)
    //   const responseBlob = new Blob([response.data], { type: response.headers.contentType })
    //   // const url = window.URL.createObjectURL(responseBlob)
    //   // window.open(url)
    // })
  }
}
