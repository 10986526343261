<template>
  <v-col cols="12">
    <crud-filho-list
      :mostra-tabela="!formulario"
      :headers="headers"
      :items="items"
      :paginacao.sync="paginacao"
      :total-registros="totalRegistros"
      :loading="loading"
      :hide-headers="$vuetify.breakpoint.xsOnly"
      @novo-event="novo"
    >
      <template
        slot="items"
        slot-scope="{item}"
      >
        <tr>
          <td
            class="justify-left btnEditar cursorPointer"
            @click="editar(item.item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
            {{ item.item.titulo }}
          </td>
          <td>
            {{ item.item.descricao }}
          </td>
          <td>
            <v-icon
              small
              color="primary"
              @click="download(item.item)"
            >
              mdi-download
            </v-icon>
          </td>
        </tr>
      </template>
    </crud-filho-list>
    <crud-filho-form
      :mostra-formulario="formulario"
      :is-editting="isEditting"
      :mensagem-pergunta-remocao="$t('padrao_deseja_remover_registro')"
      :alerta-validacao="alertValidacao"
      :mensagem-erro="error"
      :mostra-botao-salvar-continuar="false"
      id-form="anexosForm"
      @closeDialogEvent="closeDialog"
      @saveEvent="saveEvent"
      @save-continue-event="saveEvent"
      @deleteEvent="remover"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.titulo"
              :label="$t('EventoAnexos.titulo')"
            />
          </v-col>
          <v-col
            v-if="!isEditting"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.nomeArquivo"
              :label="$t('EventoAnexos.conteudo')"
              prepend-icon="mdi mdi-attachment"
              @click="pickFile"
            />
            <input
              ref="anexo"
              type="file"
              style="display: none"
              @change="onFilePicked"
            >
          </v-col>
          
          <v-col
            v-else
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="objEditar.nomeArquivo"
              :label="$t('EventoAnexos.nomeArquivo')"
              prepend-icon="mdi mdi-attachment"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              v-model="objEditar.descricao"
              outlined
              :label="$t('EventoAnexos.descricao')"
            />
          </v-col>
        </v-row>
      </v-container>
    </crud-filho-form>
  </v-col>
</template>

<script>

  import Servico from '../services/EventoAnexosService'

  export default {
    props: {
      idEvento: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        menuDataPagamento: false,
        alertValidacao: false,
        valorFinalPagamento: 0,
        diferencaValor: 0,
        valorTotalEvento: 0,

        items: [],
        paginacao: null,
        loading: false,
        totalRegistros: 0,
        fileType: null,
        objPadrao: {
          idAnexo: null,
          idEvento: null,
          titulo: null,
          descricao: null,
          conteudo: null,
          nomeArquivo: null
        },
        objEditar: {
          idAnexo: null,
          idEvento: null,
          titulo: null,
          descricao: null,
          conteudo: null,
          nomeArquivo: null
        },
        isEditting: false,
        error: null,
        required: value => !!value || 'Campo Obrigatório',
        formulario: false
      }
    },
    computed: {
      headers () {
        return [
          {
            text: this.$i18n.t('EventoAnexos.titulo'),
            value: 'titulo',
            sortable: false
          },
          {
            text: this.$i18n.t('EventoAnexos.descricao'),
            value: 'descricao',
            sortable: false
          },
          {
            text: '',
            value: 'createdAt',
            sortable: false
          }
        ]
      }

    },
    watch: {
      idEvento (val) {
        if (val > 0) {
          this.buscar()
        }
      }

    },
    mounted () {
      if (this.idEvento > 0) {
        this.buscar()
      }
    },
    methods: {

      novo () {
        this.objEditar = Object.assign({}, this.objPadrao)
        this.formulario = true
        this.editedIndex = -1
      },

      editar (registro) {
        this.editedIndex = this.items.indexOf(registro)
        Servico.findById(registro)
          .then(registro => {
            this.objEditar = Object.assign({}, registro.data)
            this.formulario = true
            this.isEditting = true
          })
          .catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
      },

      closeDialog () {
        this.formulario = false
        this.isEditting = false
        this.objEditar = Object.assign({}, this.objPadrao)
      },
      saveEvent () {
        this.formulario = false
        this.isEditting = false

        this.objEditar.idEvento = this.idEvento

        if (this.editedIndex > -1) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
        this.closeDialog()
      },

      remover () {
        const endereco = this.objEditar
        try {
          Servico.delete(endereco).then(response => {
            this.$root.$emit('mostra_dialogo_alerta', {
              dialogoTitulo: this.$i18n.t('padrao_sucesso'),
              dialogoDescricao: this.$i18n.t('padrao_registro_removido_sucesso')
            })
            this.closeDialog()
            this.buscar()
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
          })
        }
      },
      inserir (registro) {
        try {
          if(!registro.conteudo){
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('EventoAnexos.selecioneUmAnexoParaSalvar')
            })
            return
          }

          const form = document.getElementById('anexosForm')
          const formData = new FormData(form)
          formData.append('file', this.objEditar.conteudo, {
            filename: registro.nomeArquivo,
            contentType: this.fileType,
            mimeType: this.fileType
          })
          formData.append('nomeArquivo', registro.nomeArquivo)
          formData.append('titulo', registro.titulo)
          formData.append('descricao', registro.descricao)
          formData.append('idEvento', registro.idEvento)

          Servico.insert(formData).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_salvo_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: '' + error
          })
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$i18n.t('padrao_sucesso'),
                dialogoDescricao: this.$i18n.t('padrao_registro_atualizado_sucesso')
              })
              this.buscar()
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
        }
      },
      buscar () {
        try {
          const param = {
            pagination: this.paginacao,
            idEvento: this.idEvento
          }
          if (!this.idEvento) {
            return
          }
          this.loading = true

          Servico.findAll(param)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao')
                })
                return
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
                return
              }
              this.items = response.data.rows
              this.totalRegistros = response.data.count
              this.loading = false
            })
            .catch(err => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + err
              }
              )
            })
            .finally(() => {
              this.loading = false
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': ' + error
          })
          this.loading = false
        }
      },
      pickFile () {
        this.$refs.anexo.click()
      },
      onFilePicked (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.fileType = files[0].type
          this.objEditar.nomeArquivo = files[0].name
          if (this.objEditar.nomeArquivo.lastIndexOf('.') <= 0) {
            return
          }
          const reader = new FileReader()
          reader.onload = (e) => {
            let blob = new Blob([new Uint8Array(e.target.result)],{type:this.fileType})
            this.objEditar.conteudo = blob
          }
          reader.readAsArrayBuffer(files[0])
        } else {
          this.objEditar.nomeArquivo = null
          this.objEditar.conteudo = null
        }
      },
      download(anexo){
        Servico.download(anexo.id)
      }
    }
  }
</script>
